import request from '@/utils/request'

// 获取沟通对像列表
export const getchatlist = (id) => {
  return request({
    method: 'GET',
    url: '/chat/list?id=' + id
  })
}

// 获取沟通未读数量
export const chatscount = (id) => {
  return request({
    method: 'GET',
    url: '/chat/count?id=' + id
  })
}

// 对像列表编辑
export const sendchatlist = data => {
  return request({
    method: 'POST',
    url: '/chat/list',
    data: data
  })
}

// 获message取表
export const getmessage = (cmid) => {
  return request({
    method: 'GET',
    url: '/chat/message?id=' + cmid
  })
}
// message提交
export const sendmessage = data => {
  return request({
    method: 'POST',
    url: '/chat/message',
    data: data
  })
}
//

// 获取常用语表
export const getchatmb = () => {
  // alert('/chat/chatmb')
  return request({
    method: 'GET',
    url: '/chat/chatmb'
  })
}

// 常用语提交
export const sendchatmb = data => {
  return request({
    method: 'POST',
    url: '/chat/chatmb',
    data: data
  })
}
