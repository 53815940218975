<template>
<!--list---->
<div  style="width: 96%;">
  <el-row :gutter="10" style="width: 100%;">
    <el-col :span="24">
      <div style="padding-top: 12px;"><img src="/images/logoa.png" style="height: 42px;" /></div>
      <div :style="'padding:12px;width:100%;'" v-if="articles.articleid>0">
       <h4 style="margin-top: 2px; text-align: center;">{{ articles.articletitle }}</h4>
       <el-divider />
       <div style="text-align: right;font-size: 12px;margin-top: 2px;margin-bottom: 20px;">{{ String(articles.articledate).replace(/T/g, " ").replace(/Z/g, "") }}</div>
       <div style="font-size: 14px;text-align: left;line-height: 25px;">{{ articles.articlecontent }}</div>
      </div>
      <div v-if="articles.articleid==0" :style="'padding: 80px;text-align: center;color:#888888;'"><img src="/images/404.png" /><br />很抱歉这条数据不在了。。</div>
    </el-col>
  </el-row>
</div>
<!--list---->
</template>

<script>

import { articleget, articlesget } from '@/api/user'
export default {
  name: 'articlesmIndex',
  components: {
  },
  props: {},
  data () {
    return {
      id: 0,
      articleslist: [],
      articles: {},
      listheight: 500
    }
  },
  computed: {},
  watch: {},
  created () {
    this.listheight = document.documentElement.clientHeight - 518
    this.$parent.$parent.hendersetshow(false)
    this.$parent.$parent.footsetshow(false)
    if (this.$route.query.id !== undefined) {
      this.id = this.$route.query.id
    }
    this.articles.articleid = this.id

    const listwidth = document.documentElement.clientWidth
    if (listwidth > 1000) {
      this.$router.push('/articles?id=' + this.id)
    }

    this.getarticle()
  },
  mounted () {},
  methods: {
    getarticle () {
      if (this.id > 0) {
        articleget(this.id).then(res => {
          if (res.data.status === 200) {
            this.articles = res.data.resultbody
          } else {
            this.articles.articleid = 0
          }
          this.getarticles()
        })
      }
    },
    getarticles () {
      articlesget(this.articles.articlecategory, 12).then(res => {
        if (res.data.status === 200) {
          this.articleslist = res.data.resultbody
        }
      })
    }
  }
}
</script>

<style scoped lang="less">

</style>
