<template>
<div :style="stylebox">
    <div class="containinfo" v-if="ids==0" v-loading="loadingmain">
        <div class="common-layout">
            <el-row>
                <el-col :span="2" class="leftmenu" style="background-color:#161823;display: nones;">
                  <div class="meinfo">
                    <img src="/images/chatlogo.png" style="width:62px; height:62px; margin-top: 12px; margin-bottom: 128px; " />
                    <br />
                    <el-tooltip effect="dark" content="招聘首页" placement="left">
                    <el-link href="/"><el-icon style="font-size:32px;color: #777777;margin-top: 32px;"><HomeFilled /></el-icon></el-link>
                    </el-tooltip>
                    <br />
                    <el-tooltip effect="dark" content="管理中心" placement="left">
                    <el-link href="/"><el-icon style="font-size:32px;color: #777777;margin-top: 32px;"><Setting /></el-icon></el-link>
                    </el-tooltip>
                    <br />
                    <el-tooltip effect="dark" content="人才搜索" placement="left" v-if="users.userstype==2">
                    <el-link href="/" target="_blank"><el-icon style="font-size:32px;color: #777777;margin-top: 32px;"><Search /></el-icon></el-link>
                    <br />
                   </el-tooltip>
                    <el-tooltip effect="dark" content="岗位搜索" placement="left" v-if="users.userstype==1">
                    <el-link href="/" target="_blank"><el-icon style="font-size:32px;color: #777777;margin-top: 32px;"><Search /></el-icon></el-link>
                    </el-tooltip><br />
                    <el-tooltip effect="dark" content="常用语" placement="left">
                      <el-icon @click="editmb" style="font-size:32px;color: #777777;margin-top: 32px;"><ChatDotRound /></el-icon>
                    </el-tooltip>
                    <br /><br />
                    <img style="width:52px;height:52px;border-radius: 52px;" :src="chatURL+'static/resume/'+users.usersid+'/photo/'+users.usersid+'.jpg'" v-if="chatinfoa.ctype==1" onerror="this.src='/images/avatar1.png'" />
                    <img style="width:52px;height:52px;border-radius: 52px;" :src="chatURL+'static/incpic/'+users.usersid+'/logo/'+users.usersid+'.jpg'" v-if="chatinfoa.ctype==2" onerror="this.src='/images/avatar2.png'" />
                  </div>
                </el-col>
                <el-col :span="6" class="rightbox" style="display: nones;">
                  <!-- ------------------------------------ -->
                  <div class="tablebox" style="display: nones;">
                    <el-table :data="chatlist" style="padding: 0px; cursor:pointer; height:555px;" size="small" :show-header="false"  empty-text="没有数据">
                        <el-table-column width="32">
                          <template #default="scope">
                            <div style="width:100%;padding-top:8px; text-align: right;" @click="setitem(scope.row)">
                            <img style="width:26px;height:26px;border-radius: 3px;" :src="chatURL+'static/resume/'+scope.row.cid+'/photo/'+scope.row.cid+'.jpg'" v-if="scope.row.ctype==1" onerror="this.src='/images/avatar1.png'" />
                            <img style="width:26px;height:26px;border-radius: 3px;" :src="chatURL+'static/incpic/'+scope.row.cid+'/logo/'+scope.row.cid+'.jpg'" v-if="scope.row.ctype==2" onerror="this.src='/images/avatar2.png'" />
                           </div>
                        </template>
                        </el-table-column>
                        <el-table-column label="">
                          <template #default="scope">
                            <div style="width:98%;margin-left:1%;margin-left: 0px;" @click="setitem(scope.row)" :title="scope.row.cname">
                              <el-badge :value="scope.row.ccount" style="margin-top: 12px;" v-if="scope.row.ccount>0">
                                <div style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;width:132px;">
                                <span style="padding-right: 12px;color: #333333;font-size: 14px;" >{{ scope.row.cname }}</span>
                                </div>
                              </el-badge>
                                <div v-else style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;width:132px;">
                                <span style="padding-right: 12px;color: #333333;" >{{ scope.row.cname }}</span>
                              </div>
                             <div style="font-size:14px;color: #888888;">{{ scope.row.ctime }}</div>
                            </div>
                        </template>
                        </el-table-column>
                    </el-table>
                    </div>
                  <!-- ------------------------------------ -->
                </el-col>
                <el-col :span="16" class="leftbox">
                   <div class="henderbox">
                    <div class="henderinfo" v-if="chatinfob.cid>0">
                      <el-row :gutter="2">
                            <el-col :span="2">
                              <div style="width:100%; text-align: center;">
                                <img style="width:28px;height:28px;margin-top: 8px;border-radius: 3px;" :src="chatURL+'static/resume/'+chatinfob.cid+'/photo/'+chatinfob.cid+'.jpg'" v-if="chatinfob.ctype==1" onerror="this.src='/images/avatar1.png'" />
                                <img style="width:28px;height:28px;margin-top: 8px;border-radius: 3px;" :src="chatURL+'static/incpic/'+chatinfob.cid+'/logo/'+chatinfob.cid+'.jpg'" v-if="chatinfob.ctype==2" onerror="this.src='/images/avatar2.png'" />
                              </div>
                            </el-col>
                            <el-col :span="22" style="padding-top: 5px;text-align: left;font-size: 14px; color:#333333;">
                              <el-row :gutter="2" v-if="users.userstype==2">
                                <el-col :span="4" style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;"><b>{{chatinfob.cname}}</b></el-col>
                                 <el-col :span="20" style="font-size: 12px;color: #999999;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">
                                    <span>{{ resumes.resumegender }}<el-divider direction="vertical" /></span>
                                    <span v-if="resumes.resumeschool!=''&&resumes.resumeschoolage!=''">{{ resumes.resumeschool }} ({{ resumes.resumeschoolage }})<el-divider direction="vertical" /></span>
                                    <span v-if="resumes.resumeworkage!=''">{{ resumes.resumeworkage }}<el-divider direction="vertical" /></span>
                                </el-col>
                              </el-row>
                              <el-row :gutter="2" v-if="users.userstype==1">
                                <el-col :span="4" style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;"><b>{{chatinfob.cname}}</b></el-col>
                              </el-row>
                            </el-col>
                      </el-row>
                    </div>
                   </div>
                   <div class="mainbox">
                    <!-- messagelist_info --------------------------------------------------- -->
                    <img src="/images/chat_ad.jpg" style="width:98%;" v-if="chatinfob.cid==0"/>
                    <div v-if="messagelist.length==0&&chatinfob.cid>0">
                      <el-row>
                         <el-col :span="2" style="text-align:center;"></el-col>
                         <el-col :span="20" style="text-align:center;line-height: 26px; color:#888888;font-size: 12px;"><span style="padding:8px;border-radius: 12px;font-size: 12px; background-color: #f0f0f0; ">跟 {{ chatinfob.cname }} 还没有沟通过快说点什么吧！</span></el-col>
                         <el-col :span="2" style="text-align:center;"></el-col>
                        </el-row>
                    </div>
                    <el-scrollbar height="468px" style="width:608px;" ref="myScrollbar" v-if="chatinfob.cid>0">
                      <div ref="" style="width:97%;margin-left: 1%; padding-bottom: 80px;">
                        <div v-for="item in messagelist" :key="item" style="width:99%;margin-bottom: 12px;">
                          <el-row :gutter="20">
                            <el-col :span="2">
                              <div style="width:100%;padding-top:8px; text-align: center;" v-if="item.csendid!=chatinfoa.cid">
                                <img style="width:42px;height:42px;border-radius: 42px;" :src="chatURL+'static/resume/'+item.csendid+'/photo/'+item.csendid+'.jpg'" v-if="item.csendtype==1" onerror="this.src='/images/avatar1.png'"/>
                                <img style="width:42px;height:42px;border-radius: 42px;" :src="chatURL+'static/incpic/'+item.csendid+'/logo/'+item.csendid+'.jpg'" v-if="item.csendtype==2" onerror="this.src='/images/avatar2.png'"/>
                              </div>
                            </el-col>
                            <el-col :span="20">
                              <div class="messageitemleft" v-if="item.csendid!=chatinfoa.cid">
                                <div style="margin-left: 0px;line-height: 32px;text-align: left; font-size: 12px;color:#777777;">
                                  <span>{{ item.csendname }}</span>
                                  <span style="margin-left:20px;">{{ item.ctime }}</span>
                                </div>
                                <div class="messageitemcontentleft" v-if="item.ctype===1">
                                  <span v-if="item.ccontent.split('å').length==2">刚关注了岗位 “<a :href="'/jobitem?id='+item.ccontent.split('å')[1]" target="_blank"><b style="color:#333333;font-size:16px;">{{item.ccontent.split('å')[0]}}</b></a>”</span>
                                  <span v-else>{{ item.ccontent }}</span>
                                </div>
                                <div class="messageitemcontentleft" v-if="item.ctype===3"><el-image style="max-width:200px;" :src="item.ccontent.replace('#hostphoto#', this.chatURL)" :zoom-rate="1.2" :preview-src-list="[item.ccontent.replace('#hostphoto#', this.chatURL)]" :initial-index="4" fit="cover" /></div>
                                <div class="messageitemcontentleft" v-if="item.ctype===4">
                                  <el-button round @click="downfile(item.ccontent.replace('#hostphoto#', this.chatURL))"><el-icon><Download /></el-icon>点击下载文件</el-button>
                                </div>
                              </div>
                              <div class="messageitemright" v-else>
                                <div style="margin-left: 0px;line-height: 32px; text-align: right;font-size: 12px;color:#777777;">
                                  <span>{{ item.csendname }}</span>
                                  <span style="margin-left:20px;">{{ item.ctime }}</span>
                                  <span style="margin-left:20px;"><el-tag style="border-width:0px;font-size: 10px;display: none;" size="small" type="info" v-if="item.cstatus==0">未读</el-tag></span>
                                </div>
                                <div class="messageitemcontentright" v-if="item.ctype===1">
                                  <span v-if="item.ccontent.split('å').length==2">刚关注了岗位 “<a href="/jobitem?id={{item.ccontent.split('å')[1]}}" target="_blank"><b style="color:#333333;font-size:16px;">{{item.ccontent.split('å')[0]}}</b></a>”</span>
                                      <span v-else>{{ item.ccontent }}</span>
                                </div>
                                <div class="messageitemcontentright" v-if="item.ctype===3"><el-image style="max-width:200px;" :src="item.ccontent.replace('#hostphoto#', this.chatURL)" :zoom-rate="1.2" :preview-src-list="[item.ccontent.replace('#hostphoto#', this.chatURL)]" :initial-index="4" fit="cover" /></div>
                                <div class="messageitemcontentright" v-if="item.ctype===4">
                                  <el-button round @click="downfile(item.ccontent.replace('#hostphoto#', this.chatURL))"><el-icon><Download /></el-icon>点击下载文件</el-button>
                                </div>
                              </div>
                            </el-col>
                            <el-col :span="2">
                              <div style="width:100%;padding-top:8px; text-align: center;" v-if="item.csendid==chatinfoa.cid">
                                <img style="width:42px;height:42px;border-radius: 42px;" :src="chatURL+'static/resume/'+item.csendid+'/photo/'+item.csendid+'.jpg'" v-if="item.csendtype==1" onerror="this.src='/images/avatar1.png'"/>
                                <img style="width:42px;height:42px;border-radius: 42px;" :src="chatURL+'static/incpic/'+item.csendid+'/logo/'+item.csendid+'.jpg'" v-if="item.csendtype==2" onerror="this.src='/images/avatar2.png'"/>
                              </div>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    </el-scrollbar>
                    <!-- messagelist_info --------------------------------------------------- -->
                   </div>
                   <div class="bottombox">
                    <!-- ------------------------------------ -->
                    <div class="editinfo">
                    <el-row :gutter="10" v-if="chatinfob.cid>0&&ckcompany==0&&companys.companyserver1!=0">
                        <el-col :span="2">
                          <el-tooltip class="box-item" effect="dark" content="图标" placement="top">
                            <div @click.stop="openPopover">
                            <el-popover placement="top-start" trigger="click" :width="418" popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 10px;">
                              <template #reference>
                                <el-icon><Lollipop /></el-icon>
                              </template>
                              <template #default>
                                <el-row style="cursor:pointer;" class="emrow">
                                <el-col :span="1"><img class="emicon1" :src="chatURL+'static/chat/em/em1.gif'" @click="emitem(1)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em2.gif'" @click="emitem(2)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em3.gif'" @click="emitem(3)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em4.gif'" @click="emitem(4)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em5.gif'" @click="emitem(5)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em6.gif'" @click="emitem(6)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em7.gif'" @click="emitem(7)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em8.gif'" @click="emitem(8)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em9.gif'" @click="emitem(9)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em10.gif'" @click="emitem(10)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em11.gif'" @click="emitem(11)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em12.gif'" @click="emitem(12)" /></el-col>
                                </el-row>
                                <el-row style="cursor:pointer;" class="emrow">
                                <el-col :span="1"><img class="emicon1" :src="chatURL+'static/chat/em/em17.gif'" @click="emitem(17)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em18.gif'" @click="emitem(18)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon" :src="chatURL+'static/chat/em/em19.gif'" @click="emitem(19)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon" :src="chatURL+'static/chat/em/em20.gif'" @click="emitem(20)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon" :src="chatURL+'static/chat/em/em21.gif'" @click="emitem(21)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon" :src="chatURL+'static/chat/em/em22.gif'" @click="emitem(22)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon" :src="chatURL+'static/chat/em/em23.gif'" @click="emitem(23)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon" :src="chatURL+'static/chat/em/em24.gif'" @click="emitem(24)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon" :src="chatURL+'static/chat/em/em25.gif'" @click="emitem(25)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon" :src="chatURL+'static/chat/em/em26.gif'" @click="emitem(26)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon" :src="chatURL+'static/chat/em/em27.gif'" @click="emitem(27)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon" :src="chatURL+'static/chat/em/em28.gif'" @click="emitem(28)" /></el-col>
                                </el-row>
                              </template>
                            </el-popover>
                          </div>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="2">
                          <el-tooltip class="box-item" effect="dark" content="图片" placement="top" >
                          <el-upload :before-upload="beforeuploadpic" :action="uploadurlpic" method="post" name="file" :on-success="onsuccesspic" :show-file-list="false">
                          <el-icon><Picture /></el-icon>
                           </el-upload>
                        </el-tooltip>
                        </el-col>
                        <el-col :span="2">
                          <el-tooltip class="box-item" effect="dark" content="文件" placement="top" >
                          <el-upload :before-upload="beforeuploadfile" :action="uploadurlfile" method="post" name="file" :on-success="onsuccessfile" :show-file-list="false">
                            <el-icon><Files /></el-icon>
                          </el-upload>
                        </el-tooltip>
                        </el-col>
                        <el-col :span="3" style="display: none;">
                          <el-tooltip class="box-item" effect="dark" content="常用语" placement="top">
                            <div @click.stop="openPopover">
                          <el-popover placement="top-start" trigger="click" :width="318" popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 10px;">
                            <template #reference>
                              <div style="font-size: 12px;cursor: pointer;">常用语</div>
                            </template>
                            <template #default>
                              <el-row :gutter="10">
                                <el-col :span="22">
                                  <div class="cyybox">
                                  <el-row style="cursor:pointer;" v-for="(mbitem,index) in chatmblist" :key="index" class="cyylist">
                                    <el-col :span="24" @click="setcontent(mbitem)">{{mbitem}}</el-col>
                                  </el-row>
                                  </div>
                                </el-col>
                                <el-col :span="2">
                                  <el-button color="#333333" style="float: right;" size="small" round @click="editmb">
                                    <el-icon><Edit /></el-icon>
                                  </el-button>
                                </el-col>
                              </el-row>
                            </template>
                          </el-popover>
                          </div>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="16" style="text-align:right;">
                          <div v-if="users.userstype==1">
                            <el-button color="#f0f0f0" size="small" round @click="seecompanys"><el-icon style="margin-right:6px;"><Postcard /></el-icon> 查看企业</el-button>
                            <el-button color="#f0f0f0" size="small" round @click="seejobs" ><el-icon style="margin-right:6px;"><Postcard /></el-icon> 查看职位</el-button>
                          </div>
                          <div v-if="users.userstype==2">
                            <el-button color="#f0f0f0" size="small" round @click="seeressumes">查看简历</el-button>
                          </div>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top:12px;" :gutter="5" v-if="chatinfob.cid>0&&ckcompany==0&&companys.companyserver1!=0">
                      <el-col :span="2">
                          <el-tooltip class="box-item" effect="dark" content="常用语" placement="top">
                            <div @click.stop="openPopover">
                          <el-popover placement="top-start" trigger="click" :width="318" popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 10px;">
                            <template #reference>
                              <div style="font-size: 12px;cursor: pointer;margin-top: 8px;">常用语</div>
                            </template>
                            <template #default>
                              <el-row :gutter="10">
                                <el-col :span="22">
                                  <div class="cyybox">
                                  <el-row style="cursor:pointer;" v-for="(mbitem,index) in chatmblist" :key="index" class="cyylist">
                                    <el-col :span="24" @click="setcontent(mbitem)">{{mbitem}}</el-col>
                                  </el-row>
                                  </div>
                                </el-col>
                                <el-col :span="2">
                                  <el-button color="#333333" style="float: right;" size="small" round @click="editmb"><el-icon><Edit /></el-icon></el-button>
                                </el-col>
                              </el-row>
                            </template>
                          </el-popover>
                          </div>
                          </el-tooltip>
                      </el-col>
                      <el-col :span="20">
                        <el-input style="font-size:10px;" v-model="messageitem.ccontent" placeholder="请输入沟通内容" maxlength="50" show-word-limit/>
                      </el-col>
                      <el-col :span="2" style="text-align:center;">
                        <el-button color="#333333" @click="sendmessage">发送</el-button>
                      </el-col>
                    </el-row>
                    <el-row style="margin-top:12px;text-align: center;line-height: 42px;color:#888888;" :gutter="5" v-if="chatinfob.cid==0">
                      <el-col :span="24">
                        <img src="/images/chat_ad2.jpg" v-if="chatinfob.cid==0" style="width:98%;" />
                      </el-col>
                    </el-row>
                    <el-row style="margin-top:12px;text-align: center;line-height: 42px;color:#888888;" :gutter="5" v-if="companys.companyserver1==0">
                        <el-col :span="24" style="font-size:12px;color:red;">
                          您好!您的会员权限已用完，开通请联系客服（0871-67165818）
                        </el-col>
                        </el-row>

                        <el-row style="margin-top:12px;text-align: center;line-height: 42px;color:#888888;" :gutter="5" v-else-if="ckcompany!=0&&chatinfob.cid>0">
                        <el-col :span="24" style="font-size:12px;color:red;">
                          企业用户必须通过审核才能正常使用在线沟通，客服电话（0871-67165818）
                        </el-col>
                        </el-row>
                    </div>
                    <!-- ------------------------------------ -->
                   </div>
                </el-col>
            </el-row>
        </div>
    </div>
    <div class="containinfo2" v-else v-loading="loadingchat">
      <div class="common-layout">
        <el-row>
                    <el-col :span="24" class="leftbox2">
                      <div class="henderbox2" style="background-color: #f8f8f8;">
                        <div class="henderinfo" v-if="chatinfob.cid>0">
                          <el-row :gutter="2">
                                <el-col :span="2">
                                  <div style="width:100%; text-align: center;">
                                    <img style="width:28px;height:28px;margin-top: 8px;border-radius: 28px;" :src="chatURL+'static/resume/'+chatinfob.cid+'/photo/'+chatinfob.cid+'.jpg'" v-if="chatinfob.ctype==1" onerror="this.src='/images/avatar1.png'" />
                                    <img style="width:28px;height:28px;margin-top: 8px;border-radius: 28px;" :src="chatURL+'static/incpic/'+chatinfob.cid+'/logo/'+chatinfob.cid+'.jpg'" v-if="chatinfob.ctype==2" onerror="this.src='/images/avatar2.png'" />
                                  </div>
                                </el-col>
                                <el-col :span="22" style="padding-top: 5px;text-align: left;font-size: 14px; color:#333333;">
                                  <b>{{chatinfob.cname}}</b>
                                </el-col>
                          </el-row>
                        </div>
                      </div>
                      <div class="mainbox2" style="background-color: #ffffff;border-width:0px;margin-left: 8px;margin-right: 8px;margin-top: 8px;">
                        <!-- messagelist_info --------------------------------------------------- -->
                        <img src="/images/chat_ad.jpg" v-if="chatinfob.cid==0" style="width:98%;" />
                        <div v-if="messagelist.length==0&&chatinfob.cid>0">
                          <el-row>
                            <el-col :span="2" style="text-align:center;"></el-col>
                            <el-col :span="20" style="text-align:center;line-height: 26px; color:#888888;font-size: 12px;"><span style="padding:8px;border-radius: 12px;font-size: 12px; background-color: #f0f0f0; ">跟 {{ chatinfob.cname }} 还没有沟通过快说点什么吧！</span></el-col>
                            <el-col :span="2" style="text-align:center;"></el-col>
                            </el-row>
                        </div>
                        <el-scrollbar height="298px" style="width:498px;padding-right: 8px;" ref="myScrollbar" v-if="chatinfob.cid>0">
                          <div ref="" style="width:100%; padding-bottom: 50px;">
                            <div v-for="item in messagelist" :key="item" style="width:98%;margin-bottom: 18px;">
                              <el-row>
                                <el-col :span="2">
                                  <div style="width:100%;padding-top:8px; text-align: left;" v-if="item.csendid!=chatinfoa.cid">
                                    <img style="width:28px;height:28px; border-radius: 8px;" :src="chatURL+'static/resume/'+item.csendid+'/photo/'+item.csendid+'.jpg'" v-if="item.csendtype==1" onerror="this.src='/images/avatar1.png'"/>
                                    <img style="width:28px;height:28px; border-radius: 8px;" :src="chatURL+'static/incpic/'+item.csendid+'/logo/'+item.csendid+'.jpg'" v-if="item.csendtype==2" onerror="this.src='/images/avatar2.png'"/>
                                  </div>
                                </el-col>
                                <el-col :span="20">
                                  <div class="messageitemleft" v-if="item.csendid!=chatinfoa.cid">
                                    <div style="margin-left: 0px;line-height: 32px;text-align: left; font-size: 12px;color:#999999;background">
                                      <span v-if="item.csendname.length>22">{{ item.csendname.substring(0,22) }}</span>
                                      <span v-else>{{ item.csendname }}</span>
                                      <span style="margin-left:20px;" v-if="item.ctime.length>16">{{ item.ctime.substring(2,16) }}</span>
                                    </div>
                                    <div class="messageitemcontentleft"  style="background-color: #ececec;" v-if="item.ctype===1">
                                      <span v-if="item.ccontent.split('å').length==2">刚关注了岗位 “<a :href="'/jobitem?id='+item.ccontent.split('å')[1]" target="_blank"><b style="color:#333333;font-size:16px;">{{item.ccontent.split('å')[0]}}</b></a>”</span>
                                      <span v-else>{{ item.ccontent }}</span>
                                    </div>
                                    <div class="messageitemcontentleft"  style="background-color: #ececec;" v-if="item.ctype===3"><el-image style="max-width:200px;" :src="item.ccontent.replace('#hostphoto#', this.chatURL)" :zoom-rate="1.2" :preview-src-list="[item.ccontent.replace('#hostphoto#', this.chatURL)]" :initial-index="4" fit="cover" /></div>
                                    <div class="messageitemcontentleft"  style="background-color: #ececec;" v-if="item.ctype===4">
                                      <el-button round @click="downfile(item.ccontent.replace('#hostphoto#', this.chatURL))"><el-icon><Download /></el-icon>点击下载文件</el-button>
                                    </div>
                                  </div>
                                  <div class="messageitemright" v-else>
                                    <div style="margin-left: 0px;line-height: 32px; text-align: right;font-size: 12px;color:#999999;">
                                      <span v-if="item.csendname.length>22">{{ item.csendname.substring(0,22) }}</span>
                                      <span v-else>{{ item.csendname }}</span>
                                      <span style="margin-left:20px;" v-if="item.ctime.length>16">{{ item.ctime.substring(2,16) }}</span>
                                      <span style="margin-left:20px;"><el-tag style="border-width:0px;display:none;" size="small" type="info" v-if="item.cstatus==0">未读</el-tag></span>
                                    </div>
                                    <div class="messageitemcontentright" v-if="item.ctype===1">
                                      <span v-if="item.ccontent.split('å').length==2">刚关注了岗位 “<a :href="'/jobitem?id='+item.ccontent.split('å')[1]" target="_blank"><b style="color:#ffffff;font-size:16px;">{{item.ccontent.split('å')[0]}}</b></a>”</span>
                                      <span v-else>{{ item.ccontent }}</span>
                                    </div>
                                    <div class="messageitemcontentright" v-if="item.ctype===3"><el-image style="max-width:200px;" :src="item.ccontent.replace('#hostphoto#', this.chatURL)" :zoom-rate="1.2" :preview-src-list="[item.ccontent.replace('#hostphoto#', this.chatURL)]" :initial-index="4" fit="cover" /></div>
                                    <div class="messageitemcontentright" v-if="item.ctype===4">
                                      <el-button round @click="downfile(item.ccontent.replace('#hostphoto#', this.chatURL))"><el-icon><Download /></el-icon>点击下载文件</el-button>
                                    </div>
                                  </div>
                                </el-col>
                                <el-col :span="2">
                                  <div style="width:100%;padding-top:8px; text-align: right;" v-if="item.csendid==chatinfoa.cid">
                                    <img style="width:28px;height:28px;border-radius: 8px;" :src="chatURL+'static/resume/'+item.csendid+'/photo/'+item.csendid+'.jpg'" v-if="item.csendtype==1" onerror="this.src='/images/avatar1.png'"/>
                                    <img style="width:28px;height:28px;border-radius: 8px;" :src="chatURL+'static/incpic/'+item.csendid+'/logo/'+item.csendid+'.jpg'" v-if="item.csendtype==2" onerror="this.src='/images/avatar2.png'"/>
                                  </div>
                                </el-col>
                              </el-row>
                            </div>
                          </div>
                        </el-scrollbar>
                        <!-- messagelist_info --------------------------------------------------- -->
                      </div>
                      <div class="bottombox" style="background-color: #f8f8f8;">
                        <!-- ------------------------------------ -->
                        <div class="editinfo2">
                        <el-row :gutter="10" v-if="chatinfob.cid>0&&ckcompany==0&&companys.companyserver1!=0">
                          <el-col :span="2">
                          <el-tooltip class="box-item" effect="dark" content="图标" placement="top">
                            <div @click.stop="openPopover">
                            <el-popover placement="top-start" trigger="click" :width="418" popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 10px;">
                              <template #reference>
                                <el-icon><Lollipop /></el-icon>
                              </template>
                              <template #default>
                                <el-row style="cursor:pointer;" class="emrow">
                                <el-col :span="1"><img class="emicon1" :src="chatURL+'static/chat/em/em1.gif'" @click="emitem(1)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em2.gif'" @click="emitem(2)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em3.gif'" @click="emitem(3)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em4.gif'" @click="emitem(4)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em5.gif'" @click="emitem(5)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em6.gif'" @click="emitem(6)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em7.gif'" @click="emitem(7)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em8.gif'" @click="emitem(8)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em9.gif'" @click="emitem(9)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em10.gif'" @click="emitem(10)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em11.gif'" @click="emitem(11)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em12.gif'" @click="emitem(12)" /></el-col>
                                </el-row>
                                <el-row style="cursor:pointer;" class="emrow">
                                <el-col :span="1"><img class="emicon1" :src="chatURL+'static/chat/em/em17.gif'" @click="emitem(17)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon1" :src="chatURL+'static/chat/em/em18.gif'" @click="emitem(18)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon" :src="chatURL+'static/chat/em/em19.gif'" @click="emitem(19)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon" :src="chatURL+'static/chat/em/em20.gif'" @click="emitem(20)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon" :src="chatURL+'static/chat/em/em21.gif'" @click="emitem(21)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon" :src="chatURL+'static/chat/em/em22.gif'" @click="emitem(22)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon" :src="chatURL+'static/chat/em/em23.gif'" @click="emitem(23)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon" :src="chatURL+'static/chat/em/em24.gif'" @click="emitem(24)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon" :src="chatURL+'static/chat/em/em25.gif'" @click="emitem(25)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon" :src="chatURL+'static/chat/em/em26.gif'" @click="emitem(26)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon" :src="chatURL+'static/chat/em/em27.gif'" @click="emitem(27)" /></el-col>
                                <el-col :span="1" :offset="1"><img class="emicon" :src="chatURL+'static/chat/em/em28.gif'" @click="emitem(28)" /></el-col>
                                </el-row>
                              </template>
                            </el-popover>
                          </div>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="2">
                          <el-tooltip class="box-item" effect="dark" content="图片" placement="top" >
                          <el-upload :before-upload="beforeuploadpic" :action="uploadurlpic" method="post" name="file" :on-success="onsuccesspic" :show-file-list="false">
                          <el-icon><Picture /></el-icon>
                           </el-upload>
                        </el-tooltip>
                        </el-col>
                        <el-col :span="2">
                          <el-tooltip class="box-item" effect="dark" content="文件" placement="top" >
                          <el-upload :before-upload="beforeuploadfile" :action="uploadurlfile" method="post" name="file" :on-success="onsuccessfile" :show-file-list="false">
                            <el-icon><Files /></el-icon>
                          </el-upload>
                        </el-tooltip>
                        </el-col>
                        <el-col :span="4" style="display:none;">
                          <el-tooltip class="box-item" effect="dark" content="常用语" placement="top">
                          <div @click.stop="openPopover">
                          <el-popover placement="top-start" trigger="click" :width="318" popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 10px;">
                            <template #reference>
                              <div style="font-size: 12px;cursor: pointer;">常用语</div>
                            </template>
                            <template #default>
                              <el-row :gutter="10">
                                <el-col :span="21">
                                  <div class="cyybox">
                                  <el-row style="cursor:pointer;" v-for="(mbitem,index) in chatmblist" :key="index" class="cyylist">
                                    <el-col :span="24" @click="setcontent(mbitem)">{{mbitem}}</el-col>
                                  </el-row>
                                  </div>
                                </el-col>
                                <el-col :span="3" style="display:none;">
                                  <el-button color="#333333" style="float: right;" size="small" round @click="editmb">编辑</el-button>
                                </el-col>
                              </el-row>
                            </template>
                          </el-popover>
                          </div>
                          </el-tooltip>
                        </el-col>
                        <!-- <el-col :span="15" style="text-align:right;">
                          <div v-if="users.userstype==1">
                            <el-button color="#f0f0f0" size="small" round @click="seecompanys"><el-icon style="margin-right:6px;"><Postcard /></el-icon> 查看企业</el-button>
                            <el-button color="#f0f0f0" size="small" round @click="seejobs" ><el-icon style="margin-right:6px;"><Postcard /></el-icon> 查看职位</el-button>
                          </div>
                          <div v-if="users.userstype==2">
                            <el-button color="#f0f0f0" size="small" round @click="seeressumes">查看简历</el-button>
                          </div>
                        </el-col> -->
                        </el-row>
                        <el-row style="margin-top:12px;" :gutter="5" v-if="chatinfob.cid>0&&ckcompany==0&&companys.companyserver1!=0">
                          <el-col :span="2">
                          <el-tooltip class="box-item" effect="dark" content="常用语" placement="top">
                            <div @click.stop="openPopover">
                          <el-popover placement="top-start" trigger="click" :width="318" popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 10px;">
                            <template #reference>
                              <div style="font-size: 11px;cursor: pointer;margin-top: 8px;">常用语</div>
                            </template>
                            <template #default>
                              <el-row :gutter="10">
                                <el-col :span="24">
                                  <div class="cyybox">
                                  <el-row style="cursor:pointer;" v-for="(mbitem,index) in chatmblist" :key="index" class="cyylist">
                                    <el-col :span="24" @click="setcontent(mbitem)">{{mbitem}}</el-col>
                                  </el-row>
                                  </div>
                                </el-col>
                                <el-col :span="3" style="display: none;">
                                  <el-button color="#333333" style="float: right;" size="small" round @click="editmb">编辑</el-button>
                                </el-col>
                              </el-row>
                            </template>
                          </el-popover>
                          </div>
                          </el-tooltip>
                      </el-col>
                          <el-col :span="20">
                            <el-input style="font-size:10px;" v-model="messageitem.ccontent" placeholder="请输入沟通内容" maxlength="50" show-word-limit/>
                          </el-col>
                          <el-col :span="2" style="text-align:center;">
                            <el-button color="#333333" @click="sendmessage">发送</el-button>
                          </el-col>
                        </el-row>
                        <el-row style="margin-top:12px;text-align: center;line-height: 42px;color:#888888;" :gutter="5" v-if="chatinfob.cid==0">
                          <el-col :span="24">
                            <img src="/images/chat_ad2.jpg" v-if="chatinfob.cid==0" style="width:98%;" />
                          </el-col>
                        </el-row>

                        <el-row style="margin-top:12px;text-align: center;line-height: 42px;color:#888888;" :gutter="5" v-if="companys.companyserver1==0">
                        <el-col :span="24" style="font-size:12px;color:red;">
                          您好!您的会员权限已用完，开通请联系客服（0871-67165818）
                        </el-col>
                        </el-row>

                        <el-row style="margin-top:12px;text-align: center;line-height: 42px;color:#888888;" :gutter="5" v-else-if="ckcompany!=0&&chatinfob.cid>0">
                        <el-col :span="24" style="font-size:12px;color:red;">
                          企业用户必须通过审核才能正常使用在线沟通，客服电话（0871-67165818）
                        </el-col>
                        </el-row>
                        </div>
                        <!-- ------------------------------------ -->
                      </div>
                    </el-col>
        </el-row>
      </div>
    </div>
</div>
<!-- ------------------------------------ -->
<el-drawer v-model="drawer" title="" size="60%">
      <iframe id="iframe" :src="openurl" :style="'height:'+drawerheight+'px;width:100%;background-color:#f0f0f0;margin-top:2px;'" frameborder="0"></iframe>
</el-drawer>

<el-drawer v-model="draweredit" title="" size="30%">
  <h2 style="margin-top: 2px;">常用语编辑
    <el-button @click="addmb()" color="#333333" style="float: right;font-size: 12px;" size="small" ><el-icon style="margin-right: 6px;"><Plus /></el-icon> 添加</el-button>
  </h2>

  <el-row gutter="22" v-if="chatmbitemedit==1" style="margin-bottom: 18px; border: 2px solid #161823;padding-top:12px;padding-bottom: 12px;">
  <el-col :span="20">
   <el-input v-model="chatmbitem" style="font-size: 12px;" type="textarea" />
  </el-col>
  <el-col :span="4">
    <el-button @click="sendmb()" color="#333333" style="float: right;font-size: 12px;margin-top: 8px;">保存</el-button>
  </el-col>
  </el-row>
  <div>
  <el-row gutter="22" style="cursor:pointer;margin-bottom: 8px;" v-for="(mbitem,index) in chatmblist" :key="index">
    <el-col :span="20">
      <el-input v-model="chatmblist[index]" style="font-size: 12px;padding: 2px;" type="textarea" />
    </el-col>
    <el-col :span="2" style="padding-top: 12px;text-align: center;">
      <el-button @click="edtmb(index)" color="#333333" size="small" circle ><el-icon><Edit /></el-icon></el-button>
      </el-col>
      <el-col :span="2" style="padding-top: 12px;text-align: center;">
      <el-button @click="delmb(index)" color="#dc3023" size="small" circle ><el-icon><Delete /></el-icon></el-button>
    </el-col>
  </el-row>
  </div>
</el-drawer>
</template>
<script>

import { getuserprofile, getcompanyitem, getresumeitem } from '@/api/user'
import { getchatlist, getmessage, sendchatlist, getchatmb, sendchatmb } from '@/api/chat'
import token from '@/utils/token'
import store from '@/store/index'

export default {
  name: 'userIndex',
  components: {
  },
  props: {},
  data () {
    return {
      ckcompany: 0, // 企业状态判断
      openurl: '',
      drawer: false,
      draweredit: false,
      drawerheight: 518,
      loadingmain: true,
      chatstatus: 0,
      loadingchat: true,
      websock: null,
      timer: null,
      chatURL: store.state.baseUrl,
      ids: 0,
      rid: 0,
      cid: 0,
      jobid: 0,
      jobname: '',
      users: {},
      headers: {},
      uploadurlpic: store.state.baseUrl + 'chat/upload?t=3&id=1',
      uploadurlaudio: store.state.baseUrl + 'chat/upload?t=2&id=1',
      uploadurlfile: store.state.baseUrl + 'chat/upload?t=4&id=1',
      chatloading: false,
      messageloading: true,
      chatinfoa: {
        cid: 1,
        cname: '',
        ctype: 1,
        cmid: '',
        ccount: 0,
        ctime: ''
      },
      chatinfob: { cid: 0 },
      chatlist: [],
      messageinfo: '',
      uppic: '',
      fpfile: '',
      messageitem: {
      },
      messagelist: [],
      cellStyle: {
        borderBottom: '0px solid transparent'
      },
      stylebox: 'width:100%;height: 600px;background-color: #f8f8f8;text-align: center; display:flex;',
      companys: {},
      resumes: {},
      chatmblist: [],
      chatmbitem: '',
      chatmbitemid: 0,
      chatmbitemedit: 0
    }
  },
  computed: {},
  watch: {},
  created () {
    const listheight = document.documentElement.clientHeight
    this.drawerheight = listheight - 138
    const id = this.$route.query.id
    const jobids = this.$route.query.jobid
    const jobname = this.$route.query.jobname
    if (id != null) {
      this.ids = id
    }
    if (jobids != null) {
      this.jobid = jobids
    }
    if (jobname != null) {
      this.jobname = jobname
    }
    if (this.ids > 0) {
      this.stylebox = 'width:100%;height: ' + listheight + 'px;background-color: #ffffff;text-align: center; display:flex;'
    } else {
      this.stylebox = 'width:100%;height: ' + listheight + 'px;background-color: #758a99;text-align: center; display:flex;'
      this.timer = setInterval(() => {
        this.getchatlists(this.users.usersid)
      }, 10000)
    }
    this.getusers()
  },
  onUnmounted () {
    console.log('out==========')
  },
  mounted () {},
  methods: {
    getusers () {
      getuserprofile().then(res => {
        if (res.data.resultbody != null) {
          this.users = res.data.resultbody
          if (this.users.usersstatus > 0) {
            alert('您好！帐号锁定了！')
            token.settoken('utoken', '')
            this.$router.push('/login')
          }
          if (this.users.userstype === 1) {
            // 个人登陆
            this.rid = this.users.usersid
            this.cid = this.ids
            if (this.jobid > 0) {
              this.getresumes(this.rid)
            }
            if (this.cid > 0) {
              this.getcompanys(this.cid)
            }
            this.getchatmblist()
          } else {
            // 企业登陆
            this.cid = this.users.usersid
            this.rid = this.ids
            if (this.rid > 0) {
              this.getresumes(this.rid)
            }
            if (this.cid > 0) {
              this.getcompanys(this.cid)
            }
            this.getchatmblist()
          }

          if (this.ids === 0) {
            this.getchatlists(this.users.usersid)
          } else {
            this.initWebSocket(this.ids)
          }
        } else {
          token.settoken('utoken', '')
          this.$router.push('/login')
        }
        setTimeout(() => { this.loadingmain = false }, 300)
      })
    },
    loginout () {
      token.settoken('utoken', '')
      this.$router.push('/login')
    },
    getchatlists (id) {
      this.chatloading = true
      // this.chatlist = []
      getchatlist(id).then(res => {
        this.chatloading = false
        if (res.data.resultbody != null) {
          // alert(res.data.resultbody)
          this.chatlist = res.data.resultbody
        }
        this.scrollDown()
      })
      this.scrollDown()
    },
    setitem (item) {
      this.chatinfob = item
      this.getmessages()
      this.initWebSocket(item.cid)
      this.getinfo(item.cid)
    },
    remove (item) {
      alert(item.cid)
    },
    beforeuploadfile (rawFile) {
      if (rawFile.size / 1024 / 1024 > 1) {
        alert('请上传1M以内的文件！')
        return false
      }
      return true
    },
    // 上传文件
    onsuccessfile (uploadFilea, uploadFileb, uploadFilec) {
      // this.upfile = this.chatUrl + '' + uploadFileb.response.data.replace('/users/king/desktop/job/job-api/', '').replace('/api/', '')
      this.upfile = '#hostphoto#' + uploadFileb.response.data.replace('/api/', '')
      this.messagesend(4, this.upfile)
    },
    // 上传图片
    beforeuploadpic (rawFile) {
      if (rawFile.size / 1024 / 1024 > 1) {
        alert('请上传1M以内的图片！')
        return false
      } else if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png') {
        alert('请上传jpg或者png格式的图片！')
        return false
      }
      return true
    },
    onsuccesspic (uploadFilea, uploadFileb, uploadFilec) {
      // alert(JSON.stringify(uploadFileb))
      this.uppic = '#hostphoto#' + uploadFileb.response.data.replace('/api/', '')
      this.messagesend(3, this.uppic)
    },
    // 发送图标
    emitem (emitem) {
      this.messagesend(3, this.chatURL + 'static/chat/em/em' + emitem + '.gif')
    },
    // -- 提交message ---------------------------------------------------
    sendmessage () {
      // alert('ca===>' + JSON.stringify(this.chatinfoa))
      // alert('cb===>' + JSON.stringify(this.chatinfob))
      if (this.messageitem.ccontent.length > 0) {
        this.messagesend(1, this.messageitem.ccontent)
      } else {
        alert('请填写信息再提交！')
      }
    },
    messagesend (tp, content) {
      // alert(content)
      // 1、文本 2、语音 3、图片 4、文件
      if (this.chatinfob.cid > 0) {
        this.messageitem.cmid = this.chatinfob.cmid
        this.messageitem.csendid = this.chatinfoa.cid
        this.messageitem.csendname = this.chatinfoa.cname
        this.messageitem.csendtype = this.chatinfoa.ctype
        this.messageitem.ctime = this.timestampToTime()
        this.messageitem.ctype = tp
        this.messageitem.cstatus = 0
        this.messageitem.ccontent = content

        const uinfoa = { uid: this.chatinfoa.cid, uname: this.chatinfoa.cname, utype: this.chatinfoa.ctype }
        const uinfob = { uid: this.chatinfob.cid, uname: this.chatinfob.cname, utype: this.chatinfob.ctype }
        const uArray = [uinfoa, uinfob]
        sendchatlist(uArray).then(res => {
          this.getchatlists(this.users.usersid)
        })
        this.websock.send(JSON.stringify(this.messageitem))
        this.messageitem.ccontent = ''
      } else {
        alert('没有选择沟通对像！')
      }
    },
    getmessages () {
      if (this.chatinfob.cid > 0) {
        this.messageloading = true
        getmessage(this.chatinfob.cmid).then(res => {
          if (res.data.resultbody != null) {
            this.messagelist = res.data.resultbody
          }
          if (this.jobid > 0) {
            // let ckadd = 0
            let ckadda = 0
            for (let ii = 0; ii < this.messagelist.length; ii++) {
              const itemstr = this.messagelist[ii].ccontent
              const arritem = itemstr.split('å')
              if (arritem.length === 2) {
                // ckadd += 1
                if (arritem[1] === this.jobid) {
                  ckadda += 1
                }
              }
            }
            if (ckadda === 0) {
              this.messagesend(1, this.jobname + 'å' + this.jobid)
            }
          }

          this.messageloading = false
          this.scrollDown()
        })
        this.scrollDown()
      }
    },
    scrollDown () {
      if (this.$refs.myScrollbar !== undefined) {
        setTimeout(() => {
          if (this.$refs.myScrollbar !== null) {
            this.$refs.myScrollbar.setScrollTop(20000)
          }
        }, 50)
      }
    },
    downfile (fpath) {
      const a = document.createElement('a')
      a.style.display = 'none'
      a.download = 'xx'
      a.href = fpath
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    openpic (picpath) {
      alert(picpath)
    },
    // 获取企业信息
    getcompanys (cid) {
      getcompanyitem(cid, 0, 0).then(res => {
        if (res.data.status === 200) {
          this.companys = res.data.resultbody
          if (this.users.userstype === 2) {
            this.ckcompany = this.companys.companystatus
            this.chatinfoa = {
              cid: parseInt(this.companys.companyusersid),
              cname: this.companys.companyname,
              ctype: 2,
              cmid: this.rid + '-' + this.cid,
              ccount: 0,
              ctime: ''
            }
          } else {
            this.chatinfob = {
              cid: parseInt(this.companys.companyusersid),
              cname: this.companys.companyname,
              ctype: 2,
              cmid: this.rid + '-' + this.cid,
              ccount: 0,
              ctime: ''
            }
            this.getmessages()
          }
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！' + JSON.stringify(res),
          type: 'error'
        })
      })
    },
    // 获取个人信息
    getresumes (id) {
      getresumeitem(id, 0).then(res => {
        if (res.data.status === 200) {
          this.resumes = res.data.resultbody
          if (this.users.userstype === 1) {
            this.chatinfoa = {
              cid: parseInt(this.resumes.resumeusersid),
              cname: this.resumes.resumename,
              ctype: 1,
              cmid: this.rid + '-' + this.cid,
              ccount: 0,
              ctime: ''
            }
          } else {
            this.chatinfob = {
              cid: parseInt(this.resumes.resumeusersid),
              cname: this.resumes.resumename,
              ctype: 1,
              cmid: this.rid + '-' + this.cid,
              ccount: 0,
              ctime: ''
            }
            this.getmessages()
          }
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！' + JSON.stringify(res),
          type: 'error'
        })
      })
    },
    // 初始化websocket
    initWebSocket (id) {
      if (typeof (WebSocket) === 'undefined') {
        alert('您的浏览器不支持socket')
      } else {
        if (this.websock != null) {
          this.websock.close()
        }
        const tokeninfo = token.gettoken('utoken')
        const userId = id
        const wsuri = store.state.wsUrl + 'chat/chats?id=' + userId + '&tk=' + tokeninfo
        // 初始化socket方法
        this.websock = new WebSocket(wsuri)
        // 监听socket连接
        this.websock.onopen = this.open
        // 监听socket错误信息
        this.websock.onerror = this.error
        // 监听socket消息
        this.websock.onmessage = this.getMessage
        // 监听socket关闭
        this.websock.onclose = this.close
        this.getchatlists(this.users.usersid)
      }
    },
    open: function () {
      this.chatstatus = 1
      setTimeout(() => { this.loadingchat = false }, 300)
    },
    error: function () {
      alert('连接错误')
      this.chatstatus = 0
    },
    getMessage: function (msg) {
      // alert('msg -- change')
      this.messagelist.push(JSON.parse(msg.data))
      this.scrollDown()
    },
    sendmessages: function () {
      this.websock.send('params')
    },
    close: function () {
      this.chatstatus = 0
      this.loadingchat = true
    },
    timestampToTime () {
      const current = new Date()// 实例化Date对象
      const nowYear = current.getFullYear()// 获取当前的年份
      const nowMonth = this.repair(current.getMonth() + 1)// 默认显示的是0-11月，比我们正常的月份少一个月，所以要+1
      const nowdates = this.repair(current.getDate())// 获取日期
      const nowhour = this.repair(current.getHours())// 时
      const nowminute = this.repair(current.getMinutes())// 分
      const nowsecond = this.repair(current.getSeconds())// 秒

      return nowYear + '-' + nowMonth + '-' + nowdates + ' ' + nowhour + ':' + nowminute + ':' + nowsecond
    },
    repair (i) {
      if (i >= 0 && i <= 9) {
        return '0' + i
      } else {
        return i
      }
    },
    // 看简历
    seeressumes () {
      const url = '/resumeitembasic?id=' + this.chatinfob.cid
      // window.open(url, '_blank')
      this.openurl = url
      this.drawer = true
    },
    // 看职位
    seejobs () {
      const url = '/companyitembasic?id=' + this.chatinfob.cid + '&tt=job'
      // window.open('/companyitem?id=' + this.chatinfob.cid + '&tt=job', '_blank')
      this.openurl = url
      this.drawer = true
    },
    // 看企业
    seecompanys () {
      const url = '/companyitembasic?id=' + this.chatinfob.cid
      // window.open('/companyitem?id=' + this.chatinfob.cid, '_blank')
      this.openurl = url
      this.drawer = true
    },
    // 获取信息
    getinfo (id) {
      if (this.users.userstype === 1) {
        // 获取企业信息
        getcompanyitem(id, 0, 0).then(res => {
          if (res.data.status === 200) {
            this.companys = res.data.resultbody
          }
        })
      } else {
        // 获取简历信息
        getresumeitem(id, 0).then(res => {
          if (res.data.status === 200) {
            this.resumes = res.data.resultbody
          }
        })
      }
    },
    getchatmblist () {
      getchatmb().then(res => {
        this.chatloading = false
        if (res.data.resultbody != null) {
          this.chatmblist = res.data.resultbody
          const chatmblistnew = []
          for (let i = 0; i < this.chatmblist.length; i++) {
            if (this.chatmblist[i].length > 0) {
              chatmblistnew.push(this.chatmblist[i])
            }
          }
          this.chatmblist = chatmblistnew
        }
      })
    },
    setcontent (contentitem) {
      this.messageitem.ccontent = contentitem
    },
    editmb () {
      this.draweredit = true
    },
    // 编辑模版
    edtmb (mi) {
      this.chatmbitemedit = 1
      this.chatmbitem = this.chatmblist[mi]
      this.chatmbitemid = mi
    },
    // 删除模版
    delmb (mi) {
      this.chatmblist.splice(mi, 1)
      sendchatmb(this.chatmblist.toString()).then(res => {
        alert('操作成功')
        // this.draweredit = false
        // this.chatmbitemedit = 0
        // this.chatmbitem = ''
        // this.chatmbitemid = 0
      })
    },
    // 添加模版
    addmb () {
      // this.chatmblist.push('')
      this.chatmbitemedit = 1
      this.chatmbitem = ''
      this.chatmbitemid = 0
    },
    // 提交模版
    sendmb () {
      if (this.chatmbitem.length > 0) {
        if (this.chatmbitemid === 0) {
          this.chatmblist.push(this.chatmbitem)
        }
        if (this.chatmbitemid > 0) {
          this.chatmblist[this.chatmbitemid] = this.chatmbitem
        }

        sendchatmb(this.chatmblist.toString()).then(res => {
          alert('操作成功')
          // this.draweredit = false
          this.chatmbitemedit = 0
          this.chatmbitem = ''
          this.chatmbitemid = 0
        })
      } else {
        alert('模版内容不能为空！')
      }
    }
  }
}
</script>

<style lang="less"  scoped="scoped">
.containinfo{
    width: 988px;
    height: 586px;
    border-color: #cccccc; border-width: 0px; border-style: solid;
    border-radius:0px;
    background-color: #f0f0f0;
    padding-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
}
.containinfo2{
    width: 528px;
    height: 418px;
    border-color: #cccccc; border-width: 0px; border-style: solid;
    border-radius:0px;
    // background-color: #f0f0f0;
    padding-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    align-self: top;
    margin-top: 6px;
}
.leftmenu{height:586px;}
.meinfo{
    line-height: 32px;
    text-align: center;
    font-size: 12px;
    color: #999999;
    align-items: flex-end;
    height: 258px;
    b{color: #333333;font-size: 16px;}
  }
.leftbox{
padding-left: 9px;
padding-right: 9px;
background-color: #ffffff;
border-left-color: #ececec;
border-left-style: solid;
border-left-width: 1px;
}
.leftbox2{
padding-left: 0px;
padding-right: 0px;
background-color: #f8f8f8;
border-color: #cccccc;
border-style: solid;
border-width: 1px;
padding-top: 10px;
padding-bottom:10px;
border-radius: 8px;
}
.rightbox{
    // border-top-left-radius:6px;
    // border-bottom-left-radius:6px;
    background-color:#ffffff;
}
.henderbox{height: 52px;
background-color: #ffffff;
border-bottom-color: #ececec;
border-bottom-style: solid;
border-bottom-width: 1px;
}
.henderbox2{height: 42px;
background-color: #ffffff;
border-bottom-color: #ececec;
border-bottom-style: solid;
border-bottom-width: 1px;
}
.henderinfo{padding-left: 20px;padding-right: 20px; line-height: 36px;}
.mainbox{padding:15px; height:388px; margin-top: 2px; border-color: #ffffff;border-width: 1px; border-style: solid;border-radius:6px;background-color: #ffffff;
}
.mainbox2{padding:15px; height:288px; margin-top: 2px; border-color: #ffffff;border-width: 1px; border-style: solid;border-radius:6px;background-color: #ffffff;
}
.tablebox{
  width:80%; margin-left:6%; margin-top: 12px;border-color: #ececec; border-width: 0px; border-style: solid;border-radius:6px;padding:8px;background-color: #ffffff;
}
.bottombox{
  margin-top: 12px;
  // width: 98%;
  border-top-color: #ececec;
  height: 92px;
  border-top-width: 1px; border-top-style: solid;background-color: #ffffff;
}
.editinfo{ margin-top: 12px; width: 94%;margin-left: 2%;}

.editinfo2{ margin-top: 12px; width: 92%;margin-left: 2%;margin-right:3%;}
.emrow{width:100%;margin-top: 12px;margin-bottom: 12px;}
.emrow2{width:80%;margin-top: 12px;margin-bottom: 12px;}
.emicon{ width: 28px;height:28px;}
.emicon1{ margin-top: 12px;}
.messageitemleft{text-align: left;}
.messageitemcontentleft{padding:8px;border-radius: 12px;border-top-left-radius:0px; font-size: 14px; background-color: #f0f0f0;width: auto; height: auto;display: inline-block;}
.messageitemright{text-align: right;}
.messageitemcontentright{text-align: left;padding:8px;border-radius: 12px;border-top-right-radius:0px;font-size: 14px; background-color: #f00056; color:#ffffff;width: auto; height: auto;display: inline-block;}

.cyybox{height:168px;overflow-y: auto; overflow-x:hidden;padding-left: 6px;padding-right: 20px;}
.cyylist{
  line-height: 18px;font-size: 12px;
  border: #cccccc 1px solid;
  border-radius: 12px;
  padding:8px;
  margin-bottom: 12px;
}
.el-divider {
    margin-top:3px;
    margin-bottom: 6px;
  }
</style>
