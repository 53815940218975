<template>
    <!--footbox---->
    <div class="kmzp_footbox">
      <el-row :gutter="20" class="listbox">
          <el-col :span="24">
            <el-row :gutter="58">
              <el-col :span="6" class="footitem">
                  <h4 style="margin-top: 8px;margin-bottom: 6px;">关于我们</h4>
                  <el-row :gutter="20">
                    <el-col :span="12" v-for="item in aboutlist" :key="item.articleid"  style="line-height: 26px;font-size: 12px;padding-left: 20px;color: #555555;">
                    <a :href="'/articles?id='+item.articleid" target="_blank">{{ item.articletitle }}</a>
                    </el-col>
                    <el-col :span="24" style="margin-top: 20px;">
                      <el-button color="#e00303" size="small" style="border-radius: 16px;">服务时间：周一至周五 9:00至17:30</el-button>
                    </el-col>
                  </el-row>
              </el-col>
              <el-col :span="6" class="footitem">
                <h4 style="margin-top: 8px;margin-bottom: 6px;">使用帮助</h4>
                  <el-row :gutter="20">
                    <el-col :span="12" v-for="item in helplist" :key="item.articleid"  style="line-height: 26px;font-size: 12px;padding-left: 20px;color: #555555;">
                    <a :href="'/articles?id='+item.articleid" target="_blank">{{ item.articletitle }}</a>
                    </el-col>
                  </el-row>
              </el-col>
              <el-col :span="12" class="footitem">
                <h4 style="margin-top: 8px;margin-bottom: 6px;">联系我们</h4>
                  <el-row :gutter="20">
                    <el-col :span="24" style="line-height: 26px;font-size: 12px;padding-left: 20px;color: #555555;">
                      服务热线：0871-67165818 67167718 传真：0871-67165828<br />
                      业务咨询QQ：1105511835、1216059408<br />
                      求职服务QQ：1216059408<br />
                      通讯地址：昆明市北京路605号驰宇大厦8楼
                    </el-col>
                  </el-row>
              </el-col>
            </el-row>
            <el-divider border-style="double" />
          </el-col>
          <el-col :span="24" class="listbox2">
          Copyright © 2024 开门招聘（云南）人力资源有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="margin-left: 16px;color:#333333;"><b>滇ICP备18009977号</b></a> <span style="margin-left: 16px;">增值电信业务经营许可证：<b>滇B2-20220163</b></span>
          <span style="margin-left: 16px;"><a href="https://beian.mps.gov.cn/#/query/webSearch?code=53010302001462" rel="noreferrer" target="_blank" style="color: #333333;"><img src="http://www.kmzp.com/images/ga.jpeg" style="border: 0px;height: 18px; margin-bottom: -5px;margin-right: 3px;" />滇公网安备53010302001462</a></span>
          <br />
未经www.kmzp.com同意，不得转载昆明招聘网招聘信息，不得将昆明招聘网招聘信息用于其他商业用途 本站常年法律顾问团：北京大成（昆明）律师事务所
        </el-col>
        </el-row>
    </div>
</template>
<script>

import { gethomejsons } from '@/api/user'
export default {
  name: 'homeFoot',
  components: {},
  data () {
    return {
      aboutlist: [],
      helplist: []
    }
  },
  created () {
    this.getlist()
  },
  computed: {},
  watch: {},
  methods: {
    getlist () {
      // 帮助
      gethomejsons('/homes/jsons?t=article&at=3&pagesize=6').then(res => {
        this.helplist = []
        if (res.data != null) {
          this.helplist = res.data
        }
      })
      // 关于我们
      gethomejsons('/homes/jsons?t=article&at=2&pagesize=6').then(res => {
        this.aboutlist = []
        if (res.data != null) {
          this.aboutlist = res.data
        }
      })
    }
  }
}
</script>
